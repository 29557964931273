// Firebase Configuration

const firebaseCompanyConfig = {
  apiKey: "AIzaSyCvJ1egGOo_G6adYPws_PODviggYrPxvQE",
  authDomain: "yuze-cafe-spice.firebaseapp.com",
  projectId: "yuze-cafe-spice",
  storageBucket: "yuze-cafe-spice.appspot.com",
  messagingSenderId: "42938459185",
  appId: "1:42938459185:web:ab3506a6eb9320950f6110",
  measurementId: "G-3F91TE0KD4"
};

const firebaseBackOfficeConfig = {
  apiKey: "AIzaSyC-DsWeMrcWpnLHcWdSXKuJ2vtJbA5dLGs",
  authDomain: "fyre-pos.firebaseapp.com",
  databaseURL: "https://fyre-pos.firebaseio.com",
  projectId: "fyre-pos",
  storageBucket: "fyre-pos.appspot.com",
  messagingSenderId: "179766960980",
  appId: "1:179766960980:web:3c2cbbff655c8547e8dec1",
  measurementId: "G-KLRDX1529N"
};


export { firebaseCompanyConfig, firebaseBackOfficeConfig }
